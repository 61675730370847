export type StaticBrewery = {
  Code: string;
  Name: string;
  AltCode?: string;
};

// As part of #114485, duplicate and blank names from breweries (BVL2, CVL2, HFX, LDN) were removed.
// When we get brewery data from an API someday, we will still need to make sure to exclude these.
export const breweryData: StaticBrewery[] = [
  {
    Code: "MILS",
    Name: "Mill Street",
  },
  {
    Code: "TENB",
    Name: "Ten Barrel Brewing",
  },
  {
    Code: "HAL",
    Name: "Halifax (Labatt)",
  },
  {
    Code: "VTCD",
    Name: "Virtue Cider",
  },
  {
    Code: "ARN",
    Name: "Arnold",
  },
  {
    Code: "10B",
    Name: "Ten Barrel Brewing",
  },
  {
    Code: "SGLE",
    Name: "Sazerac - The Glenmore Distillery",
  },
  {
    Code: "FPK2",
    Name: "Four Peaks Brewing Co. (Wilson St.)",
  },
  {
    Code: "EMF3",
    Name: "Elk Mountain 3",
  },
  {
    Code: "MCMO",
    Name: "Molson Coors - Montreal",
  },
  {
    Code: "RPS5",
    Name: "Pflug (RPS5)",
  },
  {
    Code: "CUTS",
    Name: "Cutwater Spirits",
  },
  {
    Code: "SCC8",
    Name: "SCC8-Materials Overhead",
  },
  {
    Code: "JKS",
    Name: "Jacksonville Can",
  },
  {
    Code: "JLIC",
    Name: "JLIC (Labatt)",
  },
  {
    Code: "NEW",
    Name: "Newburgh",
  },
  {
    Code: "APMB",
    Name: "Applachian Mountain Brewery",
  },
  {
    Code: "TOPP",
    Name: "TopPop",
  },
  {
    Code: "SCCR",
    Name: "SCCO-RPB",
  },
  {
    Code: "DTEX",
    Name: "Waco",
  },
  {
    Code: "GIC",
    Name: "Goose Island Beer Co. (Chicago, IL)",
  },
  {
    Code: "BABE",
    Name: "Babe - Varni",
  },
  {
    Code: "MHD",
    Name: "Moorhead",
  },
  {
    Code: "LMW1",
    Name: "Mississauga (LMW)",
  },
  {
    Code: "WYMO",
    Name: "Wymore Transfer",
  },
  {
    Code: "DVIR",
    Name: "Virginia",
  },
  {
    Code: "PLFM",
    Name: "Platform Beer",
  },
  {
    Code: "MER",
    Name: "Merrimack",
  },
  {
    Code: "STL",
    Name: "St. Louis",
  },
  {
    Code: "cBRK",
    Name: "Breckenridge Brewery",
  },
  {
    Code: "SCCB",
    Name: "SCCO-BQI",
  },
  {
    Code: "MCTO",
    Name: "Molson Coors - Toronto",
  },
  {
    Code: "ARB",
    Name: "Archibalds",
  },
  {
    Code: "COL",
    Name: "Columbus",
  },
  {
    Code: "BPB3",
    Name: "Blue Point Brewing Two Roads",
  },
  {
    Code: "WIN",
    Name: "Windsor",
  },
  {
    Code: "LA",
    Name: "Los Angeles",
  },
  {
    Code: "FCL",
    Name: "Fort Collins",
  },
  {
    Code: "WDMR",
    Name: "Widmer",
  },
  {
    Code: "STJ",
    Name: "St. John's (Labatt)",
  },
  {
    Code: "CALI",
    Name: "Califormulations",
  },
  {
    Code: "GRCI",
    Name: "Granite City Repack (RPS8)",
  },
  {
    Code: "SCCC",
    Name: "SCCO-CCYP",
  },
  {
    Code: "PRTS",
    Name: "Portsmouth",
  },
  {
    Code: "BCK",
    Name: "Breckenridge Brewery",
  },
  {
    Code: "EDM",
    Name: "Edmonton (Labatt)",
  },
  {
    Code: "MSB",
    Name: "Mill Street",
  },
  {
    Code: "IDF",
    Name: "Idaho Falls",
  },
  {
    Code: "DGRA",
    Name: "Granite City",
  },
  {
    Code: "JAX",
    Name: "Jacksonville",
  },
  {
    Code: "GTWY",
    Name: "Gateway-GA",
  },
  {
    Code: "EPI",
    Name: "Eagle Packaging (EPI) LP",
  },
  {
    Code: "MTL",
    Name: "Montreal (Labatt)",
  },
  {
    Code: "AD19",
    Name: "Allen Distribution (PAME) BDLG 19",
  },
  {
    Code: "AGIL",
    Name: "Agile Repack",
  },
  {
    Code: "FCBR",
    Name: "Fort Collins Barley Research",
  },
  {
    Code: "HTN",
    Name: "Houston",
  },
  {
    Code: "KARB",
    Name: "Karbach",
  },
  {
    Code: "AD01",
    Name: "Allen Distribution BLDG 1",
  },
  {
    Code: "FPK1",
    Name: "Four Peaks Brewing Co. (8th St. Pub)",
  },
  {
    Code: "EVGR",
    Name: "Evergrain STL",
  },
  {
    Code: "SCCA",
    Name: "SCCO-Automation",
  },
  {
    Code: "GOLD",
    Name: "Golden Road Brewing",
  },
  {
    Code: "MOO",
    Name: "Moosehead",
  },
  {
    Code: "DMIC",
    Name: "Howell",
  },
  {
    Code: "ELYS",
    Name: "Elysia Brewing",
  },
  {
    Code: "LAK",
    Name: "Lakeport",
  },
  {
    Code: "EMF",
    Name: "Elk Mountain Farms",
  },
  {
    Code: "SUPC",
    Name: "SUPC-Supply Canada",
  },
  {
    Code: "LGC",
    Name: "Longhorn Glass",
  },
  {
    Code: "WHN",
    Name: "Wuhan",
  },
  {
    Code: "FPK",
    Name: "Four Peaks Brewing Co. (8th St. Pub)",
  },
  {
    Code: "VEZS",
    Name: "Veza Sur",
  },
  {
    Code: "DVBB",
    Name: "Devils Backbone",
  },
  {
    Code: "EMF2",
    Name: "Elk Mountain 2",
  },
  {
    Code: "LON",
    Name: "London (Labatt)",
  },
  {
    Code: "SCC9",
    Name: "SCCO-9th Street",
  },
  {
    Code: "BPB1",
    Name: "Blue Point Brewing Company",
  },
  {
    Code: "CREO",
    Name: "Creston Co-Op",
  },
  {
    Code: "NWK",
    Name: "Newark",
  },
  {
    Code: "SCCM",
    Name: "SCCM-Maintenance",
  },
  {
    Code: "ARCH",
    Name: "Archibald",
  },
  {
    Code: "ELY",
    Name: "Elysia Brewing",
  },
  {
    Code: "BPDC",
    Name: "Blue Point Brewing KM Davies",
  },
  {
    Code: "DPOR",
    Name: "Portland",
  },
  {
    Code: "MEX",
    Name: "Mexico City, Mexico",
  },
  {
    Code: "BAYE",
    Name: "Bayers",
  },
  {
    Code: "BRLY",
    Name: "Barley Operations",
  },
  {
    Code: "BVL",
    Name: "Baldwinsville",
  },
  {
    Code: "GAKE",
    Name: "Pflug (GAKE)",
  },
  {
    Code: "MONC",
    Name: "Moncton",
  },
  {
    Code: "SCCO",
    Name: "SCCO",
  },
  {
    Code: "CRE",
    Name: "Creston (Labatt)",
  },
  {
    Code: "JBO",
    Name: "Jonesboro",
  },
  {
    Code: "OKC",
    Name: "Oklahoma City",
  },
  {
    Code: "WKDW",
    Name: "Wicked Weed",
  },
  {
    Code: "BPB2",
    Name: "Blue Point Brewing High Falls",
  },
  {
    Code: "SCCV",
    Name: "SCCV-VP Supply",
  },
  {
    Code: "TPB",
    Name: "Stanley Park",
  },
  {
    Code: "FSB",
    Name: "Goose Island Beer Co. (Chicago, IL)",
  },
  {
    Code: "DCOL",
    Name: "Colorado",
  },
  {
    Code: "MIR",
    Name: "Mira Loma",
  },
  {
    Code: "RIV",
    Name: "Riverside",
  },
  {
    Code: "FFD",
    Name: "Fairfield",
  },
  {
    Code: "ARC",
    Name: "Archibald",
  },
  {
    Code: "MLS",
    Name: "Mill Street",
  },
  {
    Code: "MCIN",
    Name: "McClary / Intermountain Nutrition",
  },
  {
    Code: "OCTO",
    Name: "Octopi",
  },
  {
    Code: "GDR",
    Name: "Golden Road Brewing",
  },
  {
    Code: "MCCC",
    Name: "MCC-Corp",
  },
  {
    Code: "SBAR",
    Name: "Sazerac - Barton Distillery",
  },
  {
    Code: "SCCX",
    Name: "SCCX-CAPEX",
  },
  {
    Code: "WMS",
    Name: "Williamsburg",
  },
  {
    Code: "CVL",
    Name: "Cartersville",
  },
  {
    Code: "IMPO",
    Name: "Import",
  },
  {
    Code: "BIAG",
    Name: "Biagi Brothers - Ontario (ONTA)",
  },
  {
    Code: "RPB",
    Name: "Research Pilot Brewery",
  },
];
