import { view_plant } from "../../abi-brewdat-naz-supply/codegen/models/view_plant";
import { StaticBrewery, breweryData } from "./exportedBreweryArray";

export const formatBreweryDisplay = (
  brewery: StaticBrewery | view_plant,
  useAlt = false,
) => {
  const code = useAlt ? brewery.AltCode : brewery.Code;
  return `${brewery.Name?.trim()} (${code?.trim()})`;
};

export const loadStaticBreweriesList = () => {
  return breweryData
    .map((brewery) => formatBreweryDisplay(brewery))
    .sort((a, b) => (a < b ? -1 : 1));
};

export const findBrewery = ({
  breweryCode,
  breweries = breweryData,
  useAltCode = false,
}: {
  breweryCode: string;
  breweries?: view_plant[];
  useAltCode?: boolean;
}): string => {
  const matchedBreweries = useAltCode
    ? breweries.filter((brewery) => brewery.AltCode === breweryCode)
    : breweries.filter((brewery) => brewery.Code === breweryCode); // Make this alt code

  if (matchedBreweries.length === 0 || matchedBreweries.length > 1) {
    console.error("Error: Brewery not found");
    return breweryCode;
  }

  return formatBreweryDisplay(matchedBreweries[0], useAltCode);
};

//API consts
export const getCodeFromFormattedString = (s: string) =>
  s.substring(s.lastIndexOf("(") + 1, s.lastIndexOf(")")).trim();

// see AB#114485
export const sanitizeBreweriesList = (
  breweries: Array<StaticBrewery>,
): Array<StaticBrewery> => {
  const codesToRemove = ["CVL2", "BVL2"];
  return breweries.filter(
    (brewery) =>
      brewery.Name.trim() !== "" && !codesToRemove.includes(brewery.Code),
  );
};

//extract the variables to display
//format and sort
export const loadAndFormatApiBreweriesList = (
  jsonArray: Array<view_plant>,
): Array<string> => {
  const nonNullData = jsonArray.filter(
    (item) =>
      item.Code !== null &&
      item.Name !== null &&
      item.Code !== undefined &&
      item.Name !== undefined,
  );

  const filteredJson = nonNullData.map((item) => ({
    Code: item.Code,
    Name: item.Name,
  })) as Array<StaticBrewery>; //is there a better way?
  const sanitizedResult = sanitizeBreweriesList(filteredJson);
  return sanitizedResult
    .map((brewery) => formatBreweryDisplay(brewery))
    .sort((a, b) => (a < b ? -1 : 1));
};
