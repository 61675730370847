import { ABIAppBar, LightOrDark } from "@ab-inbev-labs/ux-react-components";
import { FC, useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { UserProfile } from "./UserProfile";
import { HelpMenu } from "./HelpMenu";
import { LogoutButton } from "./LogoutButton";
import CollapsibleBreadcrumb from "./CollapsibleBreadcrumb";
import { AdditionalUserProfileSettingsContent } from "./userProfileSettings/types";

type AppBarWithBreadcrumbProps = {
  appTitle: string;
  isThisRenderedInvisibleOnWebpagePrint: boolean;
  showUserProfile?: boolean;
  additionalUserProfileSettings?: AdditionalUserProfileSettingsContent;
};

const AppBarWithBreadcrumb: FC<AppBarWithBreadcrumbProps> = ({
  appTitle,
  isThisRenderedInvisibleOnWebpagePrint,
  showUserProfile = true,
  additionalUserProfileSettings,
}) => {
  const { doesCurrentApplicationUseSidebar } = useContext(SidebarContext);

  const classNames =
    "sam-header " +
    `${doesCurrentApplicationUseSidebar ? "sam-header-with-sidebar" : ""} ` +
    `${isThisRenderedInvisibleOnWebpagePrint ? "no-print" : ""} `;

  return (
    <ABIAppBar
      appTitle={appTitle}
      theme={LightOrDark.Light}
      breadcrumb={
        <CollapsibleBreadcrumb isThisRenderedInvisibleOnWebpagePrint />
      }
      className={classNames}
    >
      <div className="user-profile-actions">
        <HelpMenu />

        {showUserProfile && (
          <UserProfile additionalSettings={additionalUserProfileSettings} />
        )}

        {showUserProfile && <LogoutButton />}
      </div>
    </ABIAppBar>
  );
};

export default AppBarWithBreadcrumb;
