import {
  ABIActionDrawer,
  ABIIconButton,
  DrawerContainerPosition,
  OpenInNewIcon,
} from "@ab-inbev-labs/ux-react-components";
import { useTranslation } from "react-i18next";
import "./UserProfile.scss";

const reportIssueLink =
  "https://solutionsnow.one.ofc.loc/plugins/servlet/desk/portal/4/create/3125";

const submitEnhancementLink =
  "https://solutionsnow.one.ofc.loc/plugins/servlet/desk/portal/4/create/3124";

const userGuideLink =
  "https://ab-inbev.acadia.sysalli.com/browse/ZATS-500350/en-us";
export const HelpMenu = () => {
  const { t } = useTranslation();

  const createHelpMenuButton = (text: string, link: string) => {
    return (
      <ABIIconButton onClick={() => window.open(link, "_blank")}>
        {t(text)}
        {
          <OpenInNewIcon
            color="var(--color-contrast-focused)"
            height={18}
            width={18}
          />
        }
      </ABIIconButton>
    );
  };

  return (
    <ABIActionDrawer
      drawerContainerPosition={DrawerContainerPosition.BottomRight}
      closeOnContainerClick
      className="general-help-drawer"
      drawerClassName="general-help-action-drawer"
      buttonContent={
        <div className="general-help">{t("common.actions.help")}</div>
      }
    >
      <div className="profile-help-wrap">
        {createHelpMenuButton("helpLinks.reportAnIssue", reportIssueLink)}
        {createHelpMenuButton(
          "helpLinks.enhancementReq",
          submitEnhancementLink,
        )}
        {createHelpMenuButton("helpLinks.userGuides", userGuideLink)}
      </div>
    </ABIActionDrawer>
  );
};
