import {
  ABIAppButton,
  ABIModal,
  AppButtonVariant,
  ModalSize,
} from "@ab-inbev-labs/ux-react-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type MessageWithCloseButtonProps = {
  i18KeyForMessage: string;
  i18KeyForTitle: string;
  jsonBlob?: string;
  show: boolean;
  onClose: () => void;
  "data-testid"?: string;
  "button-data-testid"?: string;
};

export const MessageWithCloseButton: FC<MessageWithCloseButtonProps> = ({
  i18KeyForMessage,
  i18KeyForTitle,
  jsonBlob,
  show,
  onClose,
  "data-testid": dataTestId,
  "button-data-testid": buttonDataTestId,
}) => {
  const i18next = useTranslation();
  const errorData = !jsonBlob ? {} : JSON.parse(jsonBlob);

  return (
    <ABIModal
      title={i18next.t(i18KeyForTitle)}
      isHeaderBorderBottom={false}
      show={show}
      size={ModalSize.Regular}
      disableCloseButton
      data-testid={dataTestId}
    >
      <div className="message-area">
        <div>{i18next.t(i18KeyForMessage)}</div>
        <ul>
          {Object.keys(errorData).map((key) => (
            <>
              <li>{key}</li>
              <ul>
                {(errorData[key] || []).map((value: string) => (
                  <li key={`${value}`}>{value}</li>
                ))}
              </ul>
            </>
          ))}
        </ul>
      </div>
      <div className="button-area">
        <ABIAppButton
          className="back-button-caret"
          isNotCapitalized
          variant={AppButtonVariant.Plain}
          onClick={() => onClose()}
          data-testid={buttonDataTestId}
        >
          {i18next.t("common.actions.close")}
        </ABIAppButton>
      </div>
    </ABIModal>
  );
};

export default MessageWithCloseButton;
